import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import {
  FaClock,
  FaPlus,
  FaPuzzlePiece,
  FaSchool,
  FaSlidersH,
  FaUsers,
} from "react-icons/fa";
import BenefitBlock from "../components/benefitBlock";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";

export const query = graphql`
  {
    rekrutacja: file(relativePath: { eq: "dolaczdonas.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Ogloszenie = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Customer Success Manager"
      headline="Dołącz do zespołu"
    >
      <SEO
        title="Producent oprogramowania dla firm - zadbaj o swój biznes"
        description="Producent oprogramowania dla firm ✅ Rozwiązania dedykowane • Systemy do zarządzania firmą • Systemy CRM • Platformy B2B • Systemy do serwisu"
        keywords={["Producent oprogramowania dla firm"]}
      />
      <br />

      <h2> Producent rozwiązań dla biznesu </h2>
      <br />
      <p>
        Jesteśmy{" "}
        <strong> producentem i dostawcą oprogramowania dla biznesu</strong>{" "}
        działającym od 2002 roku. Pracujemy zarówno dla dużych, rozpoznawalnych
        firm, jak i dla mniejszych – dynamicznie rozwijających się
        przedsiębiorstw. Wspieramy klientów biznesowymi narzędzami, które
        umożliwiają realizację strategii, zapewniając tym samym lepsze i
        przyjemniejsze jutro. Nasz zespół składa się z około 40 specjalistów,
        których łączy wspólna pasja — technologie informacyjne.
      </p>
      <br />
      <br />
      <h2> Wartości, które wyznajemy:</h2>
      <br />
      <p>
        <ul>
          <li>
            {" "}
            stosujemy dobre praktyki zarządzania, a za główny miernik wartości
            uznajemy osiągane rezultaty,{" "}
          </li>
          <li> stawiamy na ciągłe samodoskonalenie, rozwój i edukację, </li>
          <li> do osiągania celów wykorzystujemy wiedzę i doświadczenie, </li>
          <li>
            {" "}
            traktujemy klientów i pracowników jako partnerów, z którymi wspólnie
            budujemy przyszłość,{" "}
          </li>
          <li> w codziennej pracy cenimy zaangażowanie i pasję, </li>
          <li>
            {" "}
            stawiamy na otwartą komunikację – jeśli coś nie działa, mówimy o tym
            głośno,{" "}
          </li>
          <li>
            {" "}
            nie jesteśmy korporacją – u nas wszyscy się znają i współpracują,{" "}
          </li>
          <li>
            {" "}
            stawiamy na pracę zespołową, bo sukces w naszej branży zależy od
            zaangażowania wielu osób,{" "}
          </li>
          <li>
            {" "}
            i… last but not least – bardzo lubimy technologie informatyczne!{" "}
          </li>
        </ul>
      </p>
      <br />
      <br />
      <center>
        {" "}
        <h2> Dlaczego warto z nami pracować? </h2>{" "}
      </center>
      <br />

      <div>
        <BenefitBlockWrapper>
          <BenefitBlock
            icon={<FaClock size="60px" />}
            title="Mamy już 18 lat doświadczenia&nbsp;w biznesie"
          />
          <BenefitBlock
            icon={<FaUsers size="60px" />}
            title="40 fanów technologii na pokładzie"
          />
          <BenefitBlock
            icon={<FaPuzzlePiece size="60px" />}
            title="Realizujemy projekty z różnych dziedzin"
          />
        </BenefitBlockWrapper>
        <BenefitBlockWrapper>
          <BenefitBlock
            icon={<FaSchool size="60px" />}
            title="Dzielimy się wiedzą i doświadczeniem"
          />
          <BenefitBlock
            icon={<FaSlidersH size="60px" />}
            title="Jesteśmy elastyczni"
          />
          <BenefitBlock
            icon={<FaPlus size="60px" />}
            title="Oferujemy dodatkowe benefity"
          />
        </BenefitBlockWrapper>
      </div>

      <br />
      <h2> Oferta pracy </h2>
      <center>
        <h3>Customer Success Manager</h3>
        <p> Miejsce pracy: Poznań </p>
      </center>
      <br />
      <p>
        <strong>
          Twoim celem będzie wsparcie klienta w osiągnięciu sukcesu poprzez
          wykorzystanie naszych rozwiązań biznesowych.
        </strong>
      </p>
      <br />
      <p>
        <strong>Zakres odpowiedzialności i zadania:</strong>
      </p>
      <p>
        <ul>
          <li>wsparcie klienta w zarządzaniu zmianą w organizacji,</li>
          <li>
            wsparcie klienta w skutecznym rozpoczęciu eksploatacji rozwiązań
            informatycznych,
          </li>
          <li>
            edukowanie klienta w zakresie efektywnego wykorzystania rozwiązań,
          </li>
          <li>dbanie o szybkie rozwiązywanie problemów klienta,</li>
          <li>wsparcie w podstawowej konfiguracji systemów,</li>
          <li>
            aktywna współpraca z działem sprzedaży, wsparcia i marketingu,
          </li>
          <li>rozwijanie relacji z naszymi klientami.</li>
        </ul>
      </p>
      <br />
      <p>
        <strong>Nasz idealny kandydat posiada następujące cechy:</strong>
      </p>
      <p>
        <ul>
          <li>komunikatywność i uprzejmość,</li>
          <li>zaangażowanie w rozwój biznesu klienta,</li>
          <li>systematyczność i dokładność,</li>
          <li>postrzeganie problemu jako okazji do doskonalenia,</li>
          <li>pozytywna energia i nastawienie do wyzwań,</li>
          <li>
            otwartość na opinie innych w celu poszerzania własnych horyzontów.
          </li>
        </ul>
      </p>
      <br />
      <p>
        <strong>...umiejętności:</strong>
      </p>
      <p>
        <ul>
          <li>umiejętność ustalania priorytetów oraz organizacji pracy,</li>
          <li>praca pod presją czasu (klienci chcą wiedzieć szybko :) ),</li>
          <li>umiejętności rozwiązywania problemów.</li>
        </ul>
      </p>
      <br />
      <p>
        <strong>...oraz kompetencje (mile widziane):</strong>
      </p>
      <p>
        <ul>
          <li>doświadczenie na podobnym stanowisku,</li>
          <li>
            podstawowa wiedza z zakresu rozwiązań IT w obszarze sprzedaży i
            obsługi klienta.
          </li>
        </ul>
      </p>
      <br />
      <p>Spełniasz większość z podanych przez nas wymagań? Aplikuj!</p>
      <br />
      <p>
        {" "}
        <strong> Co zyskujesz dołączając do naszego zespołu? </strong>{" "}
      </p>
      <p>
        <ul>
          <li>
            {" "}
            Lojalnego pracodawcę, partnera zainteresowanego długoterminową
            współpracą z Tobą;
          </li>
          <li>
            {" "}
            Ciekawą pracę w dynamicznej firmie w zespole młodych ludzi
            nastawionych na przyszłość i rozwój;
          </li>
          <li>
            {" "}
            Szansę na realizowanie własnych pomysłów, bo w naszej firmie
            stawiamy na innowacyjność wypływającą z zespołu;
          </li>
          <li>
            {" "}
            Wsparcie doświadczonego (i fajnego) zespołu, zainteresowanego grą do
            jednej bramki;
          </li>
          <li> Przyjazną atmosferę i spotkania integracyjne;</li>
          <li>
            {" "}
            Pracę w nowoczesnym biurze w centrum Poznania (3 min pieszo z Dworca
            Zachodniego);
          </li>
          <li> Pyszną kawę każdego dnia 🙂;</li>
          <li>
            {" "}
            Forma zatrudnienia do ustalenia indywidualnie (umowa zlecenie, UoP,
            B2B);
          </li>
        </ul>
      </p>
      <br />
      <br />
      <h2> Tworzymy zgrany zespół </h2>
      <br />
      <br />
      <center>
        <p>
          To, co motywuje nas do codziennego działania to współpraca. Każdego
          dnia wspólnie tworzymy projekty, rozwiązujemy problemy naszych
          klientów, świętujemy nasze sukcesy, świetnie się przy tym wszystkim
          bawiąc. Przecież w dobrej atmosferze obowiązki stają się
          przyjemnością, prawda?
        </p>
        <br />

        <iframe
          width="560"
          height="360"
          src="https://www.youtube.com/embed/Qa91CrMac_Y"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </center>
      <br />
      <br />
      <center>
        <h2>
          Przekonaliśmy Cię?
          <br />
          Zobacz, jak do nas dołączyć.{" "}
        </h2>{" "}
      </center>
      <br />
      <GatsbyImage
        image={data.rekrutacja.childImageSharp.gatsbyImageData}
        style={{
          maxWidth: 1200,
          margin: "0 auto",
          marginTop: 30,
        }}
        alt="CRM online dla firm."
      />

      <br />
      <br />

      <h2> Czekamy na Ciebie! </h2>
      <br />
      <br />
      <center>
        <p>
          Wyślij CV z dopiskiem <strong>„Customer Success Manager”</strong> oraz
          zgodą na przetwarzanie danych osobowych w procesie rekrutacji na adres
          <strong> kariera@questy.pl </strong>{" "}
        </p>

        <p>
          {" "}
          Informujemy, że skontaktujemy się osobiście tylko z wybranymi
          kandydatami.{" "}
        </p>
      </center>
      <br />
      <br />
      <br />

      <p>Prosimy o zawarcie w CV klauzuli: </p>

      <p>
        „Wyrażam zgodę na przetwarzanie danych osobowych zawartych w mojej
        ofercie pracy dla potrzeb niezbędnych do realizacji procesu rekrutacji
        prowadzonego przez QUESTY z siedzibą w Poznaniu zgodnie z ustawą z dnia
        29 sierpnia 1997 r. o ochronie danych osobowych (t.j. Dz. U. z 2016 r.,
        poz. 922)”. Jednocześnie wyrażam zgodę na przetwarzanie przez
        ogłoszeniodawcę moich danych osobowych na potrzeby przyszłych
        rekrutacji.”
      </p>
      <p>
        Informujemy, że Administratorem danych jest QUESTY z siedzibą w Poznaniu
        przy ul. Głogowskiej 31/33. Dane zbierane są dla potrzeb rekrutacji. Ma
        Pani/Pan prawo dostępu do treści swoich danych oraz ich poprawiania.
        Podanie danych w zakresie określonym przepisami ustawy z dnia 26 czerwca
        1974 r. Kodeks pracy oraz aktów wykonawczych jest obowiązkowe. Podanie
        dodatkowych danych osobowych jest dobrowolne.
      </p>
    </FunctionPageLayout>
  );
};

export default Ogloszenie;
